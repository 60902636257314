import { useMemo } from 'react'

import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const Client = () => {
  return useMemo(() => {
    const httpLink = createHttpLink({
      uri:
        process.env.REACT_APP_SERVER_ENDPOINT_URL ||
        'http://localhost:8000/graphql'
    })
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers
        }
      }
    })
    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
    })
    // eslint-disable-next-line
  }, [])
}
export default Client
