import { styled } from '@mui/material/styles'
import { TableCell, Typography } from '@mui/material'

const MiniCell = styled(TableCell)(() => ({
    padding: '12px 0',
    borderBottom: '0'
  }))

  const BottomCell = styled(TableCell)(() => ({
    borderBottom: '0'
  }))

  const GraphTicks = styled(Typography)(() => ({
    variant:'body14Light',
    fontSize:'12px',
    width:'0',
    textAlign:'center'            
  }))
    

export { MiniCell, BottomCell, GraphTicks}
