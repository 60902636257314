import { Jodit } from 'jodit'
import 'jodit/build/jodit.min.css'
import './style.css'

const jodit: any = Jodit.modules // Replace icons for inline-link menu
jodit.Icon.set(
  'unlink',
  `<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.9991 6.00027H15.8691C17.3291 6.00027 18.6691 6.98027 18.9491 8.42027C19.2591 10.0603 18.2091 11.5303 16.7291 11.9003L18.2591 13.4303C20.0291 12.5203 21.2091 10.6103 20.9591 8.42027C20.6791 5.86027 18.3691 4.00027 15.7891 4.00027H12.9991C12.4491 4.00027 11.9991 4.45027 11.9991 5.00027C11.9991 5.55027 12.4491 6.00027 12.9991 6.00027ZM2.50914 0.510273C2.11914 0.120273 1.48914 0.120273 1.09914 0.510273C0.709141 0.900273 0.709141 1.53027 1.09914 1.92027L3.73914 4.56027C1.96914 5.47027 0.789141 7.38027 1.03914 9.57027C1.31914 12.1403 3.62914 14.0003 6.20914 14.0003H8.99914C9.54914 14.0003 9.99914 13.5503 9.99914 13.0003C9.99914 12.4503 9.54914 12.0003 8.99914 12.0003H6.12914C4.66914 12.0003 3.32914 11.0203 3.04914 9.58027C2.73914 7.94027 3.79914 6.47027 5.26914 6.10027L7.38914 8.22027C7.15914 8.41027 6.99914 8.68027 6.99914 9.00027C6.99914 9.55027 7.44914 10.0003 7.99914 10.0003H9.16914L18.0691 18.9003C18.4591 19.2903 19.0891 19.2903 19.4791 18.9003C19.8691 18.5103 19.8691 17.8803 19.4791 17.4903L2.50914 0.510273ZM12.9991 8.00027L14.7091 9.71027C14.8891 9.53027 14.9991 9.28027 14.9991 9.00027C14.9991 8.45027 14.5491 8.00027 13.9991 8.00027H12.9991Z" fill="black"/>
</svg>`
)

jodit.Icon.set(
  'eye',
  `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15 16H3C2.45 16 2 15.55 2 15V3C2 2.45 2.45 2 3 2H8C8.55 2 9 1.55 9 1C9 0.45 8.55 0 8 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V10C18 9.45 17.55 9 17 9C16.45 9 16 9.45 16 10V15C16 15.55 15.55 16 15 16ZM11 1C11 1.55 11.45 2 12 2H14.59L5.46 11.13C5.07 11.52 5.07 12.15 5.46 12.54C5.85 12.93 6.48 12.93 6.87 12.54L16 3.41V6C16 6.55 16.45 7 17 7C17.55 7 18 6.55 18 6V1C18 0.45 17.55 0 17 0H12C11.45 0 11 0.45 11 1Z" fill="black"/>
  </svg>`
)

jodit.Icon.set(
  'pencil',
  `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.15155e-05 14.4598V17.4598C5.15155e-05 17.5924 0.05273 17.7196 0.146498 17.8134C0.240266 17.9071 0.367443 17.9598 0.500051 17.9598H3.50005C3.56563 17.9606 3.63064 17.9477 3.69092 17.9219C3.7512 17.896 3.80541 17.8579 3.85005 17.8098L14.8101 6.93982L11.0601 3.18982L0.150052 14.0998C0.101737 14.1464 0.0634943 14.2023 0.0376923 14.2642C0.0118903 14.3262 -0.000921192 14.3927 5.15155e-05 14.4598ZM17.7101 3.99982C17.8963 3.81245 18.0008 3.559 18.0008 3.29482C18.0008 3.03063 17.8963 2.77718 17.7101 2.58982L15.3701 0.289816C15.1827 0.103565 14.9292 -0.000976562 14.6651 -0.000976562C14.4009 -0.000976563 14.1474 0.103565 13.9601 0.289816L12.1301 2.11982L15.8801 5.86982L17.7101 3.99982Z" fill="#050B0E"/>
  </svg>`
)
jodit.Icon.set(
  'bold',
  `<svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.6 6.79C9.57 6.12 10.25 5.02 10.25 4C10.25 1.74 8.5 0 6.25 0H0V14H7.04C9.13 14 10.75 12.3 10.75 10.21C10.75 8.69 9.89 7.39 8.6 6.79ZM3 2.5H6C6.83 2.5 7.5 3.17 7.5 4C7.5 4.83 6.83 5.5 6 5.5H3V2.5ZM6.5 11.5H3V8.5H6.5C7.33 8.5 8 9.17 8 10C8 10.83 7.33 11.5 6.5 11.5Z" fill="black"/>
    </svg>`
)
jodit.Icon.set(
  'italic',
  `<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 0V3H6.21L2.79 11H0V14H8V11H5.79L9.21 3H12V0H4Z" fill="black"/>
    </svg>`
)
jodit.Icon.set(
  'underline',
  `<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 14C10.31 14 13 11.31 13 8V0H10.5V8C10.5 9.93 8.93 11.5 7 11.5C5.07 11.5 3.5 9.93 3.5 8V0H1V8C1 11.31 3.69 14 7 14ZM0 16V18H14V16H0Z" fill="black"/>
    </svg>`
)
jodit.Icon.set(
  'strikethrough',
  `<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.24 5.75C3.98 5.27 3.85 4.72 3.85 4.08C3.85 3.47 3.98 2.92 4.25 2.41C4.51 1.91 4.88 1.48 5.36 1.12C5.84 0.77 6.41 0.49 7.06 0.29C7.72 0.1 8.45 0 9.24 0C10.05 0 10.78 0.11 11.45 0.34C12.11 0.56 12.68 0.88 13.14 1.28C13.61 1.68 13.97 2.16 14.22 2.71C14.47 3.26 14.6 3.86 14.6 4.52H11.59C11.59 4.21 11.54 3.93 11.44 3.67C11.35 3.4 11.2 3.18 11 2.99C10.8 2.8 10.55 2.66 10.25 2.55C9.95 2.45 9.59 2.39 9.19 2.39C8.8 2.39 8.45 2.43 8.16 2.52C7.87 2.61 7.63 2.73 7.44 2.88C7.25 3.04 7.1 3.22 7 3.43C6.9 3.64 6.85 3.86 6.85 4.09C6.85 4.57 7.1 4.97 7.59 5.3C7.97 5.55 8.36 5.78 9 6H4.39C4.34 5.92 4.28 5.83 4.24 5.75ZM18 9V7H0V9H9.62C9.8 9.07 10.02 9.14 10.17 9.2C10.54 9.37 10.83 9.54 11.04 9.71C11.25 9.88 11.39 10.07 11.47 10.28C11.54 10.48 11.58 10.71 11.58 10.97C11.58 11.2 11.53 11.42 11.44 11.63C11.35 11.83 11.21 12.01 11.02 12.16C10.83 12.31 10.6 12.42 10.31 12.51C10.02 12.59 9.68 12.64 9.3 12.64C8.87 12.64 8.47 12.6 8.12 12.51C7.77 12.42 7.46 12.28 7.21 12.09C6.96 11.9 6.76 11.65 6.62 11.34C6.48 11.03 6.37 10.58 6.37 10.13H3.4C3.4 10.68 3.48 11.26 3.64 11.71C3.8 12.16 4.01 12.56 4.29 12.92C4.57 13.27 4.89 13.58 5.27 13.84C5.64 14.1 6.05 14.32 6.49 14.49C6.93 14.66 7.39 14.79 7.87 14.88C8.35 14.96 8.83 15.01 9.31 15.01C10.11 15.01 10.84 14.92 11.49 14.73C12.14 14.54 12.7 14.28 13.16 13.94C13.62 13.6 13.98 13.17 14.23 12.67C14.48 12.17 14.61 11.6 14.61 10.96C14.61 10.36 14.51 9.82 14.3 9.35C14.25 9.24 14.19 9.12 14.13 9.02H18V9Z" fill="black"/>
    </svg>`
)
jodit.Icon.set(
  'ul',
  `<svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 6.5C1.17 6.5 0.5 7.17 0.5 8C0.5 8.83 1.17 9.5 2 9.5C2.83 9.5 3.5 8.83 3.5 8C3.5 7.17 2.83 6.5 2 6.5ZM2 0.5C1.17 0.5 0.5 1.17 0.5 2C0.5 2.83 1.17 3.5 2 3.5C2.83 3.5 3.5 2.83 3.5 2C3.5 1.17 2.83 0.5 2 0.5ZM2 12.5C1.17 12.5 0.5 13.18 0.5 14C0.5 14.82 1.18 15.5 2 15.5C2.82 15.5 3.5 14.82 3.5 14C3.5 13.18 2.83 12.5 2 12.5ZM5 15H19V13H5V15ZM5 9H19V7H5V9ZM5 1V3H19V1H5Z" fill="black"/>
    </svg>`
)
jodit.Icon.set(
  'ol',
  `<svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 13H2V13.5H1V14.5H2V15H0V16H3V12H0V13ZM1 4H2V0H0V1H1V4ZM0 7H1.8L0 9.1V10H3V9H1.2L3 6.9V6H0V7ZM5 1V3H19V1H5ZM5 15H19V13H5V15ZM5 9H19V7H5V9Z" fill="black"/>
    </svg>`
)
jodit.Icon.set(
  'link',
  `<svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.9 5C1.9 3.29 3.29 1.9 5 1.9H9V0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H9V8.1H5C3.29 8.1 1.9 6.71 1.9 5ZM6 6H14V4H6V6ZM15 0H11V1.9H15C16.71 1.9 18.1 3.29 18.1 5C18.1 6.71 16.71 8.1 15 8.1H11V10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0Z" fill="black"/>
    </svg>`
)
jodit.Icon.set(
  'source',
  `<svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.4 10.6L2.8 6L7.4 1.4L6 0L0 6L6 12L7.4 10.6ZM12.6 10.6L17.2 6L12.6 1.4L14 0L20 6L14 12L12.6 10.6Z" fill="black"/>
    </svg>`
)
jodit.Icon.set(
  'eraser',
  `<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 4.00035V1.00035H4.39L7.39 4.00035H9.22L8.67 5.28035L10.76 7.38035L12.21 4.00035H18ZM1.41 0.860352L0 2.27035L6.97 9.24035L4.5 15.0004H7.5L9.07 11.3404L14.73 17.0004L16.14 15.5904L1.41 0.860352Z" fill="black"/>
    </svg>`
)

export default Jodit
