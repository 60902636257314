import React from 'react'
import { IconButton, Paper, Typography, Grid } from '@mui/material'
import { ChevronRightIcon } from 'assets/icons'
import { Color } from 'components'
import { GOAL_CATEGORY } from 'model/goalManager'
import { CATEGORY_COLOR_4_TEXT } from 'constants/'
import { KCLinkData } from 'utils/KCUrlPaser'

interface Props {
  content: KCLinkData
  goalCategory: string
}

const KCItem = ({ content, goalCategory }: Props) => {
  const { cate, title, type } = content
  const borderColor =
    CATEGORY_COLOR_4_TEXT[
      GOAL_CATEGORY[goalCategory as keyof typeof GOAL_CATEGORY]
    ] || Color.border

  return (
    <Paper
      variant='outlined'
      sx={{ borderColor: `${borderColor}`, padding: '16px' }}
    >
      <Grid
        container
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Grid
          item
          xs={9}
          sx={{
            margin: 0,
            position: 'absolute',
            top: '50%',
            left: 0,
            msTransform: 'translate(0, -50%)',
            transform: 'translate(0, -50%)'
          }}
        >
          {type === 'knowledge-center' && cate && (
            <Typography variant='body14' display='block'>
              {cate}
            </Typography>
          )}
          <Typography variant='body14Light'>{title}</Typography>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0' }}
        >
          <IconButton
            sx={{ height: 40, width: 40, backgroundColor: Color.grey100 }}
          >
            <ChevronRightIcon size={40} />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  )
}
export default KCItem
