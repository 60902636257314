import React, { useContext } from 'react'
import { Grid } from '@mui/material'
import { GoalManagerContext } from 'context/GoalManager'
import { SwitchButton } from 'components'
import { ACTIVE_STATUS } from 'model/goalManager'

const HeaderActions = () => {
  const { selectedGoal, submitEmployerGoal } = useContext(GoalManagerContext)
  const { id, activeStatus } = selectedGoal!

  return (
    <Grid container marginBottom='48px' alignItems='center'>
      <Grid item xs={6}>
        <SwitchButton
          isChecked={activeStatus === ACTIVE_STATUS.ACTIVE || false}
          setIsChecked={bool => {
            submitEmployerGoal(
              id,
              {
                activeStatus: bool
                  ? ACTIVE_STATUS.ACTIVE
                  : ACTIVE_STATUS.INACTIVE
              },
              'Active status'
            )
          }}
        >
          GOAL IS {activeStatus === ACTIVE_STATUS.ACTIVE || 'IN'}
          ACTIVE
        </SwitchButton>
      </Grid>
    </Grid>
  )
}

export default HeaderActions
