import React, { useContext } from 'react'
import { Grid, Link } from '@mui/material'
import { GoalManagerContext } from 'context/GoalManager'
import { ExpaGoalSectionTitle } from '../styledComponents'
import KCItem from './KCItem'
import { parseUrl } from 'utils/KCUrlPaser'

const KCLinks = () => {
  const { selectedGoal } = useContext(GoalManagerContext)
  const { category, kcUrls } = selectedGoal!
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ExpaGoalSectionTitle sectionTitle='Mathison resources' />
      </Grid>
      {kcUrls.map((link: string, index: number) => {
        const content = parseUrl(link)
        return (
          <Grid item xs={6} key={index}>
            <Link
              href={link}
              target='_blank'
              rel='noreferrer'
              style={{
                color: 'unset',
                textDecoration: 'unset',
                position: 'relative',
                display: 'block'
              }}
            >
              <KCItem content={content} goalCategory={category} />
            </Link>
          </Grid>
        )
      })}
    </Grid>
  )
}
export default KCLinks
