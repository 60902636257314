export const TABLE = {
  TABLE_HEADS: [
    {
      id: 'phase',
      label: 'Phase',
      alignRight: false
    },
    {
      id: 'category',
      label: 'Category',
      alignRight: false
    },
    {
      id: 'goal',
      label: 'Goals',
      alignRight: false
    }
  ]
}
