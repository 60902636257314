import React from 'react'
import { Box, styled, Typography } from '@mui/material'
import { Divider } from 'components'

interface Props {
  sectionTitle?: string
}

const ExpaGoalSectionTitle = styled(({ sectionTitle }: Props) => (
  <Box>
    <Divider sx={{ marginTop: '40px' }} />
    <Typography variant='subtitle2' textTransform='uppercase' textAlign='left'>
      {sectionTitle}
    </Typography>
  </Box>
))()

export { ExpaGoalSectionTitle }
