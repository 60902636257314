import React from 'react'
import { TableCell, TableRow, Typography, Button, Box } from '@mui/material'
import { GoalTag } from 'components'
import { parseGoalCategory } from 'utils/goalManager'

interface Props {
  data: any
  updateQuery: (prev: any) => void
  status: string
}

const TableRowBuilder = (props: Props) => {
  const { data } = props

  return (
    <TableRow key={data.nanoid} onClick={() => console.log('dialog open')}>
      <TableCell align='left'>
        <GoalTag label='CURRENT' />
      </TableCell>
      <TableCell scope='row' width='15%'>
        <GoalTag label={parseGoalCategory(data.category)} />
      </TableCell>
      <TableCell align='left' width='72%'>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography
            variant='body1'
            component='p'
            textOverflow='ellipsis'
            overflow='hidden'
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '2'
            }}
            maxWidth='600px'
          >
            {data.content}
          </Typography>
          <Button variant='action'>Edit</Button>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default TableRowBuilder
