import HeroImg from 'assets/images/hero/404officeimg.webp'

export const HERO = {
  title: 'ERROR 404 • page not found',
  message: 'It looks like this goal walked away.',
  image: HeroImg
}

export const HERO_W_NO_EI_ACCESS = {
  desc: 'This goal no longer exists or you may not have access. Email your administrator for support. In the meantime, can we recommend some articles you may find helpful?',
  btnTitle: 'Go to the Knowledge Center',
  btnUrl: '/knowledge-center'
}

export const HERO_W_BASIC_EI_ACCESS = {
  desc: 'This goal no longer exists or you may not have access. Would you like to view your other goals instead?',
  btnTitle: 'View your goals',
  btnUrl: 'goal-manager'
}
