import React, { ReactNode } from 'react'
import { Box, Typography } from '@mui/material'
import { ErrorIcon } from 'assets/icons'

interface Props {
  children: ReactNode
}
const InputErrorMessage = ({ children }: Props) => (
  <Box
    width='fit-content'
    bgcolor='#FFF2ED'
    padding='8px 16px'
    display='flex'
    alignItems='center'
    borderRadius='8px'
  >
    <ErrorIcon />
    <Typography variant='tableBold' fontWeight={600} marginLeft='10px'>
      {children}
    </Typography>
  </Box>
)

export default InputErrorMessage
