import React, { useContext } from 'react'
import { GoalCard, GoalTag } from 'components'
import { Typography, Grid, Stack } from '@mui/material'
import { GoalManagerContext } from 'context/GoalManager'
import { parseGoalCategory } from 'utils/goalManager'
import { Goal } from 'model/goalManager'
interface Props {
  goal: Goal
}

const Card = (props: Props) => {
  const { goal } = props
  const { setSelectedGoalId, selectedGoal } = useContext(GoalManagerContext)

  return (
    <GoalCard
      selected={selectedGoal?.id === goal.id}
      sx={{ marginBottom: 2 }}
      disabled={goal?.phase==="inactive"}
      onClick={() => {
        setSelectedGoalId(goal.id)
      }}
    >
      <Grid container>
        <Stack direction='row' spacing={1} marginBottom={5}>
          <GoalTag label={parseGoalCategory(goal.category)} />
          <GoalTag label={goal?.phase.toLocaleUpperCase()} variant='outlined'/>
        </Stack>
        <Typography
          variant='subtitle2'
          gutterBottom
          width='100%'
          textAlign='left'
        >
          RECOMMENDATION
        </Typography>
        <Typography
          variant='body1Medium'
          sx={{
            display: '-webkit-box',
            wordBreak: 'break-word',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: '3'
          }}
          textOverflow='ellipsis'
          overflow='hidden'
        >
          {goal.customTitle || goal.title}
        </Typography>
      </Grid>
    </GoalCard>
  )
}

export default Card
