import React, { useContext } from 'react'
import { GoalManagerContext } from 'context/GoalManager'
import { DescriptionBanner } from 'components'
import { Box, Typography } from '@mui/material'
import { ExpaGoalSectionTitle } from '../../styledComponents'

const Description = () => {
  const { selectedGoal } = useContext(GoalManagerContext)
  if (!selectedGoal) return null
  const { category, description } = selectedGoal!
  return description ? (
    <Box>
      <ExpaGoalSectionTitle sectionTitle='Why this is recommended' />
      <DescriptionBanner goalCategory={category} dense>
        <Typography variant='body16' display='block'>
          Why it matters
        </Typography>
        <Typography variant='body16Light'>{description}</Typography>
      </DescriptionBanner>
    </Box>
  ) : null
}

export default Description
