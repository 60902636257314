import { styled } from '@mui/material/styles'
import { Box, Button } from '@mui/material'
import { HERO } from './constants'

const ImageInfo = styled(Box)({
    display: 'flex',
    borderRadius: '8px',
    padding: 0,
    overflow: 'hidden'
})

const ImageBlock = styled(Box)({
    width: '50%',
    height: '711px',
    backgroundImage: `url(${HERO.image})`,
    backgroundPositionX: 'center',
    backgroundSize: 'cover',
})

const InfoBlock = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 150px',
    width: '50%',
})

const InfoButton = styled(Button)({
    lineHeight: '24px',
    whiteSpace: 'nowrap',
})

export { ImageInfo, ImageBlock, InfoBlock, InfoButton }
