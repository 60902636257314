import { styled } from '@mui/material/styles'
import {
  FormControl as MuiFormControl,
  FormControlProps,
  Button
} from '@mui/material'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'

type FormControlCustomProps = FormControlProps & { marginIndex?: number }

const FormControl = styled(MuiFormControl)(
  ({ marginIndex = 2 }: FormControlCustomProps) => ({
    marginTop: marginIndex * 8
  })
)

const ViewButton = styled(Button)({
  textDecorationLine: 'none',
  padding: '8px 16px',
  height: '32px',
  backgroundColor: '#f7f7f7',
  borderRadius: '24px',
  '&:hover': {
    textDecorationLine: 'none'
  }
})

const LoadButton = styled(LoadingButton)<LoadingButtonProps>(() => ({
  '&.MuiLoadingButton-loading': {
    width: 72,
    justifyContent: 'start'
  }
}));

export { FormControl, ViewButton, LoadButton }
