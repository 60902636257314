import { styled } from '@mui/material/styles'
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled'
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled'

const Tab = styled(TabUnstyled)`
  cursor: pointer;
  font-weight: 400;
  background-color: #f7f7f7;
  width: fit-content;
  padding: 8px 16px;
  border: none;
  border-radius: 24px;
  display: flex;
  justify-content: center;

  &.${tabUnstyledClasses.selected} {
    background-color: #373c3f;
    font-weight: 700;
    color: white;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
export default Tab
