import { styled } from '@mui/material/styles'
import { FormControl } from '../../styledComponents'
import { InputBase } from '@mui/material'
import { Color } from 'components'

const TitleInput = styled(InputBase)(() => ({
  padding: '4px 0 8px',
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    lineHeight: 1.2,
    backgroundColor: Color.grey50,
    border: `2px solid ${Color.black}`,
    fontSize: 24,
    fontWeight: 700,
    padding: 24
  },
  '&.Mui-error': {
    '& .MuiInputBase-input': {
      borderColor: '#FD404C'
    }
  }
}))

export { FormControl, TitleInput }
