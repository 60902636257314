import React, { useContext } from 'react'
import RouteProvider from './routes'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { MicroServiceContext } from 'context/MicroService'
import createApolloClient from 'utils/createApolloClient'

const App = () => {
  const { maestroClient } = useContext(MicroServiceContext)

  return (
    <ApolloProvider
      client={
        (window as any).__POWERED_BY_QIANKUN__
          ? maestroClient
          : createApolloClient()
      }
    >
      <Router>
        <RouteProvider />
      </Router>
    </ApolloProvider>
  )
}

export default App
