import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import {
  Grid,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Typography
} from '@mui/material'
import { TabsUnstyled } from '@mui/base'
import { Color, PhaseTab, PhaseTabList, CircularProgress } from 'components'
import {
  PHASE_STATE,
  VIEW_STATE,
  CATEGORIES,
  GOAL_CATEGORY
} from 'model/goalManager'
import CardView from './CardView'
import { GoalManagerContext } from 'context/GoalManager'
import GoalTable from './ListView'
import ElevationScroll from './ElevationScroll'
import { errorPathname } from 'routes/constants'
import { Divider } from 'components'

const CategoryPanel = () => {
  const {
    category,
    phase,
    setCategory,
    setPhase,
    goals,
    goalCount,
    viewState,
    isLoading,
    hasAccessToSearchParamGoal
  } = useContext(GoalManagerContext)

  const LoadingCircular = () => (
    <Grid
      item
      flexGrow={1}
      container
      alignItems='center'
      justifyContent='center'
    >
      <CircularProgress size={40} thickness={4} />
    </Grid>
  )

  if (isLoading) return <LoadingCircular />

  if (goalCount.all.total > 0) {
    if (hasAccessToSearchParamGoal) {
      const categoryPosition = (window as any).__POWERED_BY_QIANKUN__ ? 56 : 0
      return (
        <Grid item container marginTop='16px' flexGrow={1} direction='column'>
          <Grid
            item
            sx={{
              position: 'sticky',
              top: `${categoryPosition}px`,
              zIndex: 1200,
              borderBottom: `1px solid ${Color.grey300}`
            }}
          ></Grid>
          <ElevationScroll>
            <Grid
              item
              container
              padding='24px 32px'
              alignItems='center'
              borderLeft={`1px solid ${Color.grey300}`}
              borderRight={`1px solid ${Color.grey300}`}
              sx={{
                bgcolor: '#fff',
                position: 'sticky',
                top: `${categoryPosition + 49}px`,
                zIndex: 1200,
                transition: 'box-shadow 0.3s'
              }}
            >
              <Grid item xs={3.5} container justifyContent='flex-start'>
                <FormControl
                  fullWidth
                  variant='filled'
                  sx={{
                    '& .MuiInputBase-root .MuiSelect-select': {
                      paddingTop: '30px',
                      paddingBottom: '10px',
                      paddingLeft: '16px'
                    },
                    '& .MuiInputLabel-shrink': {
                      lineHeight: '20px',
                      transform: 'translate(16px, 10px) scale(1)'
                    }
                  }}
                >
                  <InputLabel>
                    <Typography variant='body14Light'>
                      View recommendations by category
                    </Typography>
                  </InputLabel>
                  <Select
                    value={category}
                    label='View recommendations by category'
                    onChange={e => {
                      console.log(e.target.value)
                      setCategory(e.target.value as GOAL_CATEGORY)
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 66,
                        horizontal: 'left'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                      },
                      PaperProps: { sx: { maxHeight: 270 } }
                    }}
                    sx={{
                      '& .MuiSelect-select .MuiTypography-root': {
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '24px'
                      }
                    }}
                  >
                    {CATEGORIES.map((category: any, index: number) => (
                      <MenuItem
                        key={index}
                        value={category.value}
                        sx={{
                          paddingLeft: '12px'
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item>
                            <Typography
                              variant='body2'
                              component={'div'}
                              fontWeight={400}
                            >
                              {category.label}
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4} container justifyContent='flex-end'></Grid>
              <Grid item xs={4.5}>
                <TabsUnstyled
                  value={phase}
                  onChange={(_, value) => {
                    setPhase(value as PHASE_STATE)
                  }}
                >
                  <PhaseTabList>
                    <PhaseTab value={PHASE_STATE.all}>All</PhaseTab>
                    <PhaseTab value={PHASE_STATE.current}>Current</PhaseTab>
                    <PhaseTab value={PHASE_STATE.later}>Later</PhaseTab>
                    <PhaseTab value={PHASE_STATE.inactive}>Inactive</PhaseTab>
                    <PhaseTab value={PHASE_STATE.completed}>Completed</PhaseTab>
                  </PhaseTabList>
                </TabsUnstyled>
              </Grid>
              <Divider
                sx={{
                  paddingTop: '24px'
                }}
              />
            </Grid>
          </ElevationScroll>
          {viewState === VIEW_STATE.card && <CardView goals={goals}></CardView>}
          {viewState === VIEW_STATE.list && <GoalTable />}
        </Grid>
      )
    }
    return <Navigate to={errorPathname} state={{ pageType: 'view-goals' }} />
  }
  return <Navigate to={errorPathname} />
}

export default CategoryPanel
