import React from 'react'

import { MicroServices } from 'model/microServices'
export const MicroServiceContext = React.createContext<MicroServices>({
  displayNotification: () => {},
  currentAccount: {
    id: '',
    employer: { name: '' },
    user: { id: '', name: '', primaryEmail: '' }
  },
  maestroClient: undefined,
  ketchupClient: undefined,
  children: undefined,
  hasAccessToViewGoalManager: false,
  hasAccessToViewDataViz: false,
  hasAccessToEditGoal: false
})

export const MicroServiceProvider = (props: MicroServices): JSX.Element => {
  return (
    <MicroServiceContext.Provider value={props}>
      {props.children}
    </MicroServiceContext.Provider>
  )
}
