import { styled } from '@mui/material/styles'
import TabsListUnstyled from '@mui/base/TabsListUnstyled'

const TabsList = styled(TabsListUnstyled)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export default TabsList
