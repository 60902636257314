import React, { useContext } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Grid } from '@mui/material'
import GoalManager from 'pages/GoalManager'
import PageNotFound from 'pages/PageNotFound'
import { errorPathname } from './constants'
import { MicroServiceContext } from 'context/MicroService'

const RouteProvider = () => {
  const { hasAccessToViewGoalManager } = useContext(MicroServiceContext)

  return (
    <Grid
      sx={{
        maxWidth: 1460,
        margin: 'auto',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Grid padding={6} sx={{ width: '100%' }}>
        <Routes>
          <Route
            path='/equity-index/goal-manager'
            element={
              hasAccessToViewGoalManager ? (
                <GoalManager />
              ) : (
                <Navigate to={errorPathname} />
              )
            }
          ></Route>
          <Route path={errorPathname} element={<PageNotFound />} />
        </Routes>
      </Grid>
    </Grid>
  )
}
export default RouteProvider
