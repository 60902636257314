import React, { ReactNode } from 'react'
import { Paper } from '@mui/material'
import { CATEGORY_COLOR_1_BKGD } from 'constants/'
import { GOAL_CATEGORY } from 'model/goalManager'

interface Props {
  children: ReactNode
  dense?: boolean
  goalCategory?: string
}

const DescriptionBanner = ({ children, dense, goalCategory }: Props) => {
  const categoryName =
    GOAL_CATEGORY[goalCategory as keyof typeof GOAL_CATEGORY] ||
    GOAL_CATEGORY.attracting_sourcing
  return (
    <Paper
      sx={{
        padding: dense ? '16px' : '20px',
        marginTop: 3,
        borderRadius: 2,
        bgcolor: CATEGORY_COLOR_1_BKGD[categoryName]
      }}
      elevation={0}
    >
      {children}
    </Paper>
  )
}
export default DescriptionBanner
