import React from 'react'
import { Table, TableRow, Typography, Paper } from '@mui/material'
import MiniRow from './MiniRow'
import { MiniCell, BottomCell, GraphTicks } from './styledComponents'

const MiniDashboard = () => {
  return (
    <Paper sx={{ padding: '32px', marginTop: '16px' }} variant='outlined'>
      <Typography variant='h7'>Current Equity Index Score</Typography>
      <Table sx={{ marginTop: '8px' }}>
        <TableRow
          sx={{
            display: 'flex',
            height: '44px',
            borderBottom: '1px solid #ACADAF'
          }}
        >
          <MiniCell width='22.5%'></MiniCell>
          <MiniCell width='43.5%'></MiniCell>
          <MiniCell
            width='12.5%'
            sx={{
              textAlign: 'center',
              borderRight: '1px solid #E5E5E5',
              borderLeft: '1px solid #E5E5E5'
            }}
          >
            <Typography variant='body14' textAlign='center'>
              TOTAL SCORE
            </Typography>
          </MiniCell>
          <MiniCell width='21.5%' sx={{ textAlign: 'center' }}>
            <Typography variant='body14' textAlign='center'>
              TOTAL ACTIVE GOALS
            </Typography>
          </MiniCell>
        </TableRow>
        <MiniRow goalCategory='defining_tracking'></MiniRow>
        <MiniRow goalCategory='attracting_sourcing'></MiniRow>
        <MiniRow goalCategory='interviewing_engaging'></MiniRow>
        <MiniRow goalCategory='onboarding_advancing'></MiniRow>
        <TableRow sx={{ display: 'flex', height: '18px' }}>
          <BottomCell width='22.5%'></BottomCell>
          <BottomCell
            width='43.5%'
            sx={{
              background:
                'repeating-linear-gradient(90deg, #E5E5E5, #E5E5E5 1px, white 0px, white 20%)'
            }}
          ></BottomCell>
          <BottomCell
            width='12.5%'
            sx={{
              borderRight: '1px solid #E5E5E5',
              borderLeft: '1px solid #E5E5E5'
            }}
          ></BottomCell>
          <BottomCell width='21.5%'></BottomCell>
        </TableRow>
        <TableRow sx={{ display: 'flex', height: '18px' }}>
          <BottomCell width='22.2%'></BottomCell>
          <BottomCell
            width='43.5%'
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <GraphTicks>0</GraphTicks>
            <GraphTicks>5</GraphTicks>
            <GraphTicks>10</GraphTicks>
            <GraphTicks>15</GraphTicks>
            <GraphTicks>20</GraphTicks>
            <GraphTicks>25</GraphTicks>
          </BottomCell>
        </TableRow>
      </Table>

      {/* Archived stuff below... waiting until we're done building out this section to delete */}
      {/* <Grid container item marginTop='8px' spacing={2}>
        <MiniCard
          goalCategory="defining_tracking"
        ></MiniCard>
        <MiniCard
          goalCategory="attracting_sourcing"
        ></MiniCard>
        <MiniCard
          goalCategory="interviewing_engaging"
        ></MiniCard>
        <MiniCard
          goalCategory="onboarding_advancing"
        ></MiniCard>
        <Grid item xs={2.4}>
          <Paper variant='outlined' sx={{ padding: '16px', height: '100%' }}>
            <Typography variant='body16'>
              <Typography variant='body16' component='div'>
                Total
              </Typography>{' '}
              active goals
            </Typography>
            <Typography variant='h2' marginTop='24px'>
              {goalCount.all.active}
            </Typography>
          </Paper>
        </Grid>
      </Grid> */}
    </Paper>
  )
}
export default MiniDashboard
