import React from 'react'
import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'
import Paper, { PaperProps } from '@mui/material/Paper'
import ImageInfoBlock from './ImageInfoBlock'

const Container = styled(Paper)<PaperProps>(() => ({
  borderRadius: '8px',
  padding: 0,
  width: '100%'
}))

const PageNotFound = () => {
    return (
    <Grid my={15} container>
      <Container>
        <ImageInfoBlock />
      </Container>
    </Grid>
  )
}

export default PageNotFound
