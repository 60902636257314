import { gql } from '@apollo/client'

//query
const GoalInput = gql`
  fragment goalInput on EmployerGoal {
    customTitle
    activeStatus
    startDate
    endDate
    owner {
      id
      name
      primaryEmail
    }
    members {
      id
      name
      primaryEmail
    }
    note
    isCompleted
    completedAt
  }
`

const GoalPreference = gql`
  fragment goalPreference on UserGoalPreference {
    selectedCategory
    selectedPhase
    selectedLayoutView
  }
`

export const USER_GOAL_PREFERENCE = gql`
  query UserGoalPreference($userId: String!) {
    userGoalPreference(userId: $userId) {
      id
      ...goalPreference
    }
  }
  ${GoalPreference}
`
export const EMPLOYER_GOALS = gql`
  query EmployerGoal($employerId: String!) {
    employerGoals(employerId: $employerId, first: 50) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          title
          employerId
          activeStatus
          category
          onTrack
          phase
          description
          assessmentVersion
          assessmentQuestion
          kcUrls
          goalId
          scoreDelta
          ...goalInput
        }
      }
    }
  }
  ${GoalInput}
`
export const GET_EMPLOYER_STAFF_ACCOUNTS = gql`
  query GetEmployerStaffAccounts(
    $first: Int
    $ehiAccessLevels: [EmployerStaffAccountEhiAccessLevel]
  ) {
    employerStaffAccounts(first: $first, ehiAccessLevels: $ehiAccessLevels) {
      count
      edges {
        node {
          id
          user {
            id
            name
            primaryEmail
          }
        }
      }
    }
  }
`

//mutation

export const UPDATE_EMPLOYER_GOAL = gql`
  mutation UpdateEmployerGoal($input: UpdateEmployerGoalInput!) {
    updateEmployerGoal(input: $input) {
      id
      phase
      ...goalInput
    }
  }
  ${GoalInput}
`

export const UPDATE_EMPLOYER_PREFERENCE = gql`
  mutation UpdateUserGoalPreference($input: UpdateUserGoalPreferenceInput!) {
    updateUserGoalPreference(input: $input) {
      id
      ...goalPreference
    }
  }
  ${GoalPreference}
`
