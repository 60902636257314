import React from 'react'
import { styled } from '@mui/material/styles'
import Chip, { ChipProps } from '@mui/material/Chip'
import { Typography } from '@mui/material'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'

interface Props {
  disabled?: boolean
  tagColor?: string
  textColor?: string
}

const GoalCountTag = styled(({ label, ...props }: Props & ChipProps) => (
  <Chip
    {...props}
    label={
      <Typography
        component='p'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '38px',
          paddingRight: '.1em'
        }}
        variant='subtitle2'
      >
        <CheckRoundedIcon sx={{ fontSize: '1rem' }}></CheckRoundedIcon>
        <span>{label}</span>
      </Typography>
    }
  />
))(({ tagColor, textColor }: Props) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '50px',
  letterSpacing: '.17em',
  height: '24px',
  borderRadius: '8px',
  backgroundColor: tagColor,
  borderColor: tagColor,
  color: textColor,
  fontSize: '12px',
  '& .MuiChip-label': {
    padding: '2px 0 1px 1px'
  }
}))

export default GoalCountTag
