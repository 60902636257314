import React from 'react'
import Box from '@mui/material/Box'
import MUICircularProgress, {
  circularProgressClasses,
  CircularProgressProps
} from '@mui/material/CircularProgress'
import { Color } from 'components'

const CircularProgress = (props: CircularProgressProps) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <MUICircularProgress
        variant='determinate'
        sx={{
          color: Color.grey100
        }}
        {...props}
        value={100}
      />
      <MUICircularProgress
        variant='indeterminate'
        disableShrink
        sx={{
          color: Color.black,
          animationDuration: '1000ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round'
          }
        }}
        {...props}
      />
    </Box>
  )
}

export default CircularProgress
