import React from 'react'
import { styled } from '@mui/material/styles'
import Paper, { PaperProps } from '@mui/material/Paper'

interface Props {
  onClick?: () => void
  disabled?: boolean
  selected?: boolean
}
const GoalCard = styled((props: Props & PaperProps) => (
  <Paper variant='outlined' {...props} />
))(({ selected, disabled }: Props) => ({
  cursor: 'pointer',
  border: '1px solid',
  borderRadius: '8px',
  borderColor: selected ? '#050B0E' : '#e5e5e5',
  backgroundColor: disabled ? '#F7F7F7' : 'white'
}))

export default GoalCard
