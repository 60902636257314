import { Moment } from 'moment'

export enum PHASE_STATE {
  all = 'all',
  current = 'current',
  later = 'later',
  inactive = 'inactive',
  completed = 'completed'
}
export const CATEGORIES = [
  { label: 'All Categories', value: 'all' },
  { label: 'Defining & Tracking', value: 'defining_tracking' },
  { label: 'Attracting & Sourcing', value: 'attracting_sourcing' },
  { label: 'Interviewing & Engaging', value: 'interviewing_engaging' },
  { label: 'Onboarding & Advancing', value: 'onboarding_advancing' }
]

export enum VIEW_STATE {
  card = 'card',
  list = 'list'
}

export enum GOAL_CATEGORY {
  'all' = 'All Categories',
  'defining_tracking' = 'Defining & Tracking',
  'attracting_sourcing' = 'Attracting & Sourcing',
  'interviewing_engaging' = 'Interviewing & Engaging',
  'onboarding_advancing' = 'Onboarding & Advancing'
}

export interface GoalNode {
  cursor: string
  node: Goal
}

export interface Goal {
  activeStatus: string
  assessmentQuestion: string
  assessmentVersion: string
  category: string
  customTitle: string
  description: string
  employerId: string
  endDate: string
  goalId: number
  id: string
  kcUrls: string[]
  members: Member[]
  onTrack: string
  owner: Member
  phase: PHASE_STATE
  scoreDelta: number
  startDate: string
  title: string
  note: string
  isCompleted: boolean
  completedAt: string
}

export enum ACTIVE_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export type Member = {
  id: string
  name: string
  primaryEmail: string
}

export type MemberEdge = {
  node: Member
}

export type EmployerGoalInput = {
  id: string
  activeStatus: ACTIVE_STATUS.ACTIVE | ACTIVE_STATUS.INACTIVE
  customTitle?: string
  owner: Member
  members: Member[]
  startDate: Moment | null
  endDate: Moment | null
  note: string
}

export type UpdatingEmployerGoal = {
  activeStatus?: ACTIVE_STATUS.ACTIVE | ACTIVE_STATUS.INACTIVE
  customTitle?: string
  owner?: Member
  members?: Member[]
  startDate?: string
  endDate?: string
  isCompleted?: boolean
  note?: string
}

interface GoalCategoryCounter {
  total: number
  active: number
}

export interface GoalCounter {
  all: GoalCategoryCounter
  defining_tracking: GoalCategoryCounter
  attracting_sourcing: GoalCategoryCounter
  interviewing_engaging: GoalCategoryCounter
  onboarding_advancing: GoalCategoryCounter
}
