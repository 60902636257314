import React, { useContext, useMemo, useState } from 'react'
import {
  Grid,
  Typography,
  Stack,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Button,
  Box
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import EastIcon from '@mui/icons-material/East'
import { GoalManagerContext } from 'context/GoalManager'
import { UserAvatar, DatePicker } from 'components'
import AdapterMoment from '@mui/lab/AdapterMoment'
import { MicroServiceContext } from 'context/MicroService'
import { LocalizationProvider } from '@mui/lab'
import moment, { Moment } from 'moment'
import { ButtonSelect } from './styledComponents'
import { Member } from 'model/goalManager'
import MenuItemNoMembers from './MenuItemNoMembers'
import SelectedMembers from './SelectedMembers'
import { ExpaGoalSectionTitle } from '../styledComponents'
import MenuItemMember from './MenuItemMember'

interface Props {
  readonly?: boolean
}

const GoalDetail = ({ readonly }: Props) => {
  const { displayNotification, currentAccount } =
    useContext(MicroServiceContext)
  const { selectedGoal, submitEmployerGoal, membersWithAccess } =
    useContext(GoalManagerContext)
  const { id, startDate: rawStartDate, endDate: rawEndDate } = selectedGoal!
  const [isShowingMore, setIsShowingMore] = useState(false)
  const [startDate, setStartDate] = useState<Moment | null>(null)
  const [endDate, setEndDate] = useState<Moment | null>(null)
  const teamMembers = selectedGoal!.members
  const teamMembersSelectLabel =
    teamMembers?.length === 0 ? 'Team members' : 'Add team members'
  useMemo(() => {
    if (rawStartDate) {
      setStartDate(moment(rawStartDate))
    } else {
      setStartDate(null)
    }
    if (rawEndDate) {
      setEndDate(moment(rawEndDate))
    } else {
      setEndDate(null)
    }
    //eslint-disable-next-line
  }, [selectedGoal!.id])

  moment.updateLocale(moment.locale(), { invalidDate: 'Date not set' })

  const submitStartDate = (dateInput: Moment) => {
    if (dateInput.isAfter(rawEndDate)) {
      displayNotification('End date can not be earlier than start date')
      return
    }
    submitEmployerGoal(
      id,
      {
        startDate: dateInput.toISOString()
      },
      'Start date'
    )
  }
  const submitEndDate = (dateInput: Moment) => {
    if (moment(rawStartDate).isAfter(dateInput)) {
      displayNotification('End date can not be earlier than start date')
      return
    }
    submitEmployerGoal(
      id,
      {
        endDate: dateInput.toISOString()
      },
      'End date'
    )
  }

  const availableTeamMembers = membersWithAccess?.filter(
    (member: any) =>
      selectedGoal?.owner?.id !== member.node.id &&
      !teamMembers.find(m => m.id === member.node.id)
  )
  const detailsTitles = {
    owner: "Who's owning this goal?",
    team: "Who's on the team?",
    date: "What's the projected timeline?"
  }

  return (
    <Box>
      <ExpaGoalSectionTitle sectionTitle='Goal details' />
      {readonly && selectedGoal?.owner?.id !== currentAccount.id ? (
        <Grid container spacing={3}>
          <Grid item xs={6} marginTop='24px'>
            <Typography variant='body16' component='p' marginBottom='8px'>
              {detailsTitles.owner}
            </Typography>
            <Typography variant='body16Light'>
              {selectedGoal?.owner?.name}
            </Typography>
          </Grid>
          <Grid item xs={6} marginTop='24px'>
            <Typography variant='body16' component='p' marginBottom='8px'>
              {detailsTitles.date}
            </Typography>
            <Stack direction='row' spacing={2} alignItems='baseline'>
              <Typography variant='body16Light'>
                {moment(rawStartDate).format('MMM YYYY')}
              </Typography>
              <Box>
                <EastIcon sx={{ verticalAlign: '-6px' }} />
              </Box>
              <Typography variant='body16Light'>
                {moment(rawEndDate).format('MMM YYYY')}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='body16' component='p' marginBottom='8px'>
              {detailsTitles.team}
            </Typography>
            {teamMembers.length > 0 &&
              teamMembers.map((member: any) => (
                <Stack
                  direction='row'
                  key={`member-${member.name}`}
                  padding='8px 0'
                  alignItems='center'
                >
                  <UserAvatar
                    username={member.name ?? member.primaryEmail}
                    size={32}
                  ></UserAvatar>
                  <Stack marginLeft={1}>
                    <Typography variant='body14'>{member.name}</Typography>
                    <Typography variant='body14Light'>
                      {member.primaryEmail}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={6} marginTop='24px'>
            <Typography variant='body16'>{detailsTitles.owner}</Typography>
            <FormControl
              fullWidth
              variant='filled'
              sx={{
                '& .MuiInputLabel-root': {
                  transform: 'translate(16px, 20px) scale(1)'
                },
                '& .MuiInputBase-root .MuiSelect-select': {
                  paddingTop: '30px',
                  paddingBottom: '10px',
                  paddingLeft: '16px'
                },
                '& .MuiInputLabel-shrink': {
                  transform: 'translate(16px, 10px) scale(1)'
                },
                '& .MuiInputLabel-shrink .MuiTypography-root': {
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '20px'
                }
              }}
            >
              <InputLabel>
                <Typography variant='body16'>Goal owner</Typography>
              </InputLabel>
              <Select
                value={selectedGoal?.owner}
                renderValue={(member: any) => {
                  return (
                    <Typography variant='body16'>
                      {member?.name ?? member?.primaryEmail}
                    </Typography>
                  )
                }}
                displayEmpty
                MenuProps={{
                  anchorOrigin: {
                    vertical: 66,
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  PaperProps: { sx: { maxHeight: 270 } }
                }}
                inputProps={{ 'aria-label': 'Without label' }}
                onChange={e => {
                  const {
                    id: ownerId,
                    name,
                    primaryEmail
                  }: Member = e.target.value as Member
                  const payload = {
                    id: ownerId,
                    name: name,
                    primaryEmail: primaryEmail
                  }
                  submitEmployerGoal(id, { owner: payload }, 'Goal Owner')
                }}
                endAdornment={
                  selectedGoal?.owner?.name ? (
                    <IconButton
                      edge='end'
                      onClick={() => {
                        submitEmployerGoal(
                          id,
                          { owner: {} as Member },
                          'Goal Owner Removal'
                        )
                      }}
                      sx={{ position: 'absolute', right: '44px' }}
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : undefined
                }
              >
                {availableTeamMembers?.length > 0 ? (
                  availableTeamMembers.map((member: any) => {
                    const {
                      node: {
                        id,
                        user: { name, primaryEmail }
                      }
                    } = member
                    return (
                      <MenuItem
                        value={{ id, name, primaryEmail } as any}
                        key={id}
                      >
                        <MenuItemMember
                          name={name}
                          primaryEmail={primaryEmail}
                        />
                      </MenuItem>
                    )
                  })
                ) : (
                  <MenuItemNoMembers />
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} marginTop='24px'>
            <Typography variant='body16'>{detailsTitles.date}</Typography>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Stack direction='row' spacing={2} alignItems='baseline'>
                <DatePicker
                  inputFormat='MMM YYYY'
                  value={startDate}
                  defaultValue={selectedGoal?.startDate}
                  onAccept={(date: Moment) => submitStartDate(date)}
                  onChange={(newValue: Moment | null) => {
                    setStartDate(newValue)
                  }}
                />
                <Box>
                  <EastIcon sx={{ verticalAlign: '-6px' }} />
                </Box>
                <DatePicker
                  inputFormat='MMM YYYY'
                  isEndMonth
                  value={endDate}
                  defaultValue={selectedGoal?.endDate}
                  onAccept={(date: Moment) => submitEndDate(date)}
                  onChange={(newValue: Moment | null) => {
                    setEndDate(newValue)
                  }}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='body16'>{detailsTitles.team}</Typography>
            <ButtonSelect
              fullWidth
              sx={{
                '& .MuiSelect-select': {
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  paddingLeft: '16px'
                }
              }}
            >
              <Select
                renderValue={() => (
                  <Typography variant='body16'>
                    {teamMembersSelectLabel}
                  </Typography>
                )}
                value={{}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 66,
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  PaperProps: { sx: { maxHeight: 270 } }
                }}
                onChange={e => {
                  let thisTeamMembers = [...teamMembers]
                  thisTeamMembers.push(e.target.value as Member)
                  thisTeamMembers = thisTeamMembers.map((m: any) => {
                    const payload = {
                      id: m.id,
                      name: m.name,
                      primaryEmail: m.primaryEmail
                    }
                    return payload
                  })
                  submitEmployerGoal(
                    id,
                    { members: thisTeamMembers },
                    'Team Members'
                  )
                }}
              >
                {availableTeamMembers?.length > 0 ? (
                  availableTeamMembers.map((member: any) => {
                    const {
                      node: {
                        id,
                        user: { name, primaryEmail }
                      }
                    } = member
                    return (
                      <MenuItem
                        value={{ id, name, primaryEmail } as any}
                        key={id}
                      >
                        <MenuItemMember
                          name={name}
                          primaryEmail={primaryEmail}
                        />
                      </MenuItem>
                    )
                  })
                ) : (
                  <MenuItemNoMembers />
                )}
              </Select>
            </ButtonSelect>
            <SelectedMembers isShowingMore={isShowingMore} />
            {teamMembers.length > 5 && (
              <Button
                variant='underline'
                sx={{ marginLeft: 'auto', display: 'block', marginTop: '8px' }}
                onClick={() => {
                  setIsShowingMore(!isShowingMore)
                }}
              >
                View {isShowingMore ? 'less' : 'more'}
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  )
}
export default GoalDetail
