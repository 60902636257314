import React from 'react'
import { styled } from '@mui/material/styles'
import { Switch as MuiSwitch, SwitchProps } from '@mui/material'
import { Color } from 'components'
const Switch = styled(props => (
  <MuiSwitch
    focusVisibleClassName='.Mui-focusVisible'
    disableRipple
    {...props}
  />
))<SwitchProps>(({ theme }) => ({
  width: 24,
  height: 16,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(8px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: Color.grey700,
        opacity: 1
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 12,
    height: 12
  },
  '& .MuiSwitch-track': {
    borderRadius: 8,
    backgroundColor: Color.grey600,
    opacity: 1,
    color: Color.grey600,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}))
export default Switch
