import React, { memo, useContext } from 'react'
import { Grid } from '@mui/material'
import TableContextProvider from 'context/TableContext'
import { Table, Color } from 'components'
import TableRowBuilder from './TableRowBuilder'
import { GoalManagerContext } from 'context/GoalManager'
import { TABLE } from './constants'

const GoalsTable = (): React.ReactElement => {
  const { goals } = useContext(GoalManagerContext)

  return (
    <Grid
      container
      position='relative'
      padding='18px 32px 32px 32px'
      border={`1px solid ${Color.grey300}`}
      borderTop='none'
      borderRadius='0px 0px 8px 8px'
    >
      <TableContextProvider
        emptyTableTitle='Create your first job to get started'
        emptyTableText='Emty goals'
        tableHeads={TABLE.TABLE_HEADS}
        TableRowBuilder={(props: any) => <TableRowBuilder {...props} />}
      >
        <Table loading={false} data={goals} noBorder />
      </TableContextProvider>
    </Grid>
  )
}

export default memo(GoalsTable)
