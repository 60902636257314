import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { theme } from 'components'
import { MicroServiceProvider } from 'context/MicroService'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { MicroServices } from 'model/microServices'

const myCache = createCache({
  speedy: false,
  key: 'css',
  prepend: true
})
interface Props {
  container?: any
}
const render = (props: MicroServices & Props) => {
  const {
    container,
    displayNotification,
    maestroClient,
    ketchupClient,
    currentAccount,
    hasAccessToViewGoalManager,
    hasAccessToViewDataViz,
    hasAccessToEditGoal
  } = props
  ReactDOM.render(
    <React.StrictMode>
      <MicroServiceProvider
        displayNotification={displayNotification}
        maestroClient={maestroClient}
        ketchupClient={ketchupClient}
        currentAccount={currentAccount}
        hasAccessToViewGoalManager={hasAccessToViewGoalManager}
        hasAccessToViewDataViz={hasAccessToViewDataViz}
        hasAccessToEditGoal={hasAccessToEditGoal}
      >
        <StyledEngineProvider injectFirst>
          <CacheProvider value={myCache}>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </CacheProvider>
        </StyledEngineProvider>
      </MicroServiceProvider>
    </React.StrictMode>,
    container
      ? container.querySelector('#namora-root')
      : document.querySelector('#namora-root')
  )
}

export const bootstrap = async () => {}

export const mount = async (props: MicroServices) => {
  render(props)
}

export const unmount = async (props: any) => {
  const { container } = props

  ReactDOM.unmountComponentAtNode(
    container
      ? container.querySelector('#namora-root')
      : document.querySelector('#namora-root')
  )
}
export const update = async (props: any) => {
  render(props)
}

if (!(window as any).__POWERED_BY_QIANKUN__) {
  render({
    displayNotification: () => {},
    currentAccount: {
      id: '',
      employer: { name: '' },
      user: { id: '', name: '', primaryEmail: '' }
    },
    maestroClient: undefined,
    ketchupClient: undefined,
    children: undefined,
    hasAccessToViewGoalManager: false,
    hasAccessToViewDataViz: false,
    hasAccessToEditGoal: false
  })
}
