import { useState, useEffect, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { GoalNode } from 'model/goalManager'
import { EMPLOYER_GOALS } from 'context/GoalManager/gql'
import { MicroServiceContext } from 'context/MicroService'

const useGoals = () => {
  const [employerGoals, setEmployerGoals] = useState<any>()
  const { currentAccount } = useContext(MicroServiceContext)
  const { data: rawGoals } = useQuery(EMPLOYER_GOALS, {
    variables: { employerId: currentAccount.employer.id }
  })

  useEffect(() => {
    if (rawGoals) {
      const { employerGoals } = rawGoals
      setEmployerGoals(employerGoals)
    }
  }, [rawGoals])

  const getRecentFiveCompletedGoals = () => {
    if (employerGoals) {
      const items = employerGoals.edges

      return items
        .filter((item: GoalNode) => item.node.isCompleted)
        .sort(
          (a: GoalNode, b: GoalNode) =>
            new Date(b.node.completedAt).getTime() -
            new Date(a.node.completedAt).getTime()
        )
        .slice(0, 5)
    }
  }

  return { employerGoals, getRecentFiveCompletedGoals }
}

export default useGoals
