import React, { useContext, useState, memo, useMemo } from 'react'
import { Button, Stack, Box } from '@mui/material'
import { GoalManagerContext } from 'context/GoalManager'
import { InputErrorMessage, RTFEditor } from 'components'
import { goalNoteCache } from 'utils/goalManager'
import { LoadButton } from '../../styledComponents'


interface Props {
  id: string
  savedNote: string
  unsavedNote: string | null
  onClose: () => void
}

const Editor = ({ id, savedNote, unsavedNote, onClose }: Props) => {
  const [content, setContent] = useState('')
  const [isActive, setIsActive] = useState(true)
  const [loading, setLoading] = useState(false)
  
  useMemo(() => {
    unsavedNote && setIsActive(false)
    setContent(unsavedNote || savedNote || '')
    // eslint-disable-next-line
  }, [id])

  const { submitEmployerGoal } = useContext(GoalManagerContext)

  const clickSave = async () => {
    await submitEmployerGoal(id, { note: content }, 'Note')
    setLoading(false)
    onClose()
  }

  return (
    <Box
      tabIndex={0}
      onBlur={e => {
        if (
          e.relatedTarget?.ariaLabel === 'active-note-button' ||
          e.relatedTarget?.nodeName === 'DIV' ||
          e.relatedTarget?.className === 'jodit-ui-button' ||
          e.relatedTarget?.className === 'jodit-ui-input__input'
        ) {
          return
        }
        setIsActive(false)
        goalNoteCache.setItem(content, id)
      }}
    >
      <RTFEditor
        id={`note-editor-${id}`}
        isActive={isActive}
        name='note-editor'
        value={content}
        onClick={() => {
          setIsActive(true)
        }}
        onChange={newValue => {
          setContent(newValue)
        }}
      />
      <Stack direction='row' justifyContent='space-between' marginTop='16px'>
        {!isActive && (
          <InputErrorMessage>
            Your updates won’t be posted until you save your changes.
          </InputErrorMessage>
        )}
        <Stack
          direction='row'
          justifyContent='right'
          flexGrow={1}
          alignItems='center'
        >
          <Button
            variant='underline'
            aria-label='active-note-button'
            onClick={() => onClose()}
          >
            Discard
          </Button>
          <LoadButton
            size='small'
            aria-label='active-note-button'
            variant='contained'
            loading={loading}
            loadingPosition="end"
            onClick={ () => {
              setLoading(true)
                clickSave()
            }}
          >
            Save
          </LoadButton>
        </Stack>
      </Stack>
    </Box>
  )
}

export default memo(Editor)
