import React, { useContext } from 'react'
import { Box } from '@mui/material'
import { GoalManagerContext } from 'context/GoalManager'
import Notes from './Notes'
import { GoalCard } from 'components'
import KCLinks from './KCLinks'

import GoalTitle from './Title'
import GoalDetail from './Detail'
import HeaderActions from './HeaderActions'
import FooterActions from './FooterActions'
import { MicroServiceContext } from 'context/MicroService'
import { EHIAccess } from 'model/access'
const ExpandedGoal = () => {
  const {
    currentAccount: { ehiAccess }
  } = useContext(MicroServiceContext)
  const { selectedGoal } = useContext(GoalManagerContext)
  const getAccessToEditGoal = (goal: any) =>
    ehiAccess === EHIAccess.FULL_EHI_ACCESS || goal.owner?.id === id
  if (!selectedGoal) {
    return (
      <GoalCard sx={{ padding: '32px', height: '100%' }} selected></GoalCard>
    )
  }
  const { isCompleted, customTitle, title, id, note } = selectedGoal
  const isReadOnly = !getAccessToEditGoal(selectedGoal) || isCompleted
  const noteIsReadOnly = !getAccessToEditGoal(selectedGoal)
  return (
    <GoalCard sx={{ padding: '32px' }} selected>
      {!isReadOnly && <HeaderActions />}
      <Box marginBottom='48px'>
        <GoalTitle
          id={id}
          customTitle={customTitle}
          rawTitle={title}
          readonly={isReadOnly}
        />
        <KCLinks />
        <GoalDetail readonly={isReadOnly} />
      </Box>
      <Notes id={id} savedNote={note} readonly={noteIsReadOnly} />
      {!isReadOnly && <FooterActions />}
    </GoalCard>
  )
}
export default ExpandedGoal
