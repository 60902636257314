import React, { ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import { Button as MuiButton, ButtonProps, Typography } from '@mui/material'
import { Color, Switch } from 'components'

interface Props {
  isChecked: boolean
  setIsChecked: (boo: boolean) => void
  children: ReactNode
}
const SwitchButton = styled(
  ({ isChecked, setIsChecked, children, ...props }: Props & ButtonProps) => (
    <MuiButton
      disableRipple
      variant='outlined'
      startIcon={<Switch checked={isChecked} />}
      onClick={() => {
        setIsChecked(!isChecked)
      }}
      {...props}
    >
      <Typography
        variant='subtitle2'
        lineHeight='15px'
        color={isChecked ? Color.black : Color.grey600}
      >
        {children}
      </Typography>
    </MuiButton>
  )
)<ButtonProps>(() => ({
  height: 32,
  borderRadius: 24,
  padding: '0 12px',
  borderColor: Color.grey200,
  '& .MuiButton-startIcon': {
    marginLeft: 0
  },
  '&:hover': { backgroundColor: 'unset' }
}))

export default SwitchButton
