import { Goal } from 'model/goalManager'

export enum EHIAccess {
  FULL_EHI_ACCESS = 'FULL_EHI_ACCESS',
  BASIC_EHI_ACCESS = 'BASIC_EHI_ACCESS',
  NO_EHI_ACCESS = 'NO_EHI_ACCESS'
}

export type Access = {
  hasAccessToGoalManager: boolean
  hasAccessToEditGoal: (goal: Goal) => boolean
  hasAccessToViewDashboardAndAssessment: boolean
  hasAccessToViewDataViz: boolean
  hasAccessToViewAssessment: boolean
  hasAccessToViewDashboard: boolean
  hasAccessToViewGoalManager: boolean
}
